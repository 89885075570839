.container-main {
    /*max-width: 700px;*/
    /*width:100%;*/
    /*margin: 20px auto 0;*/
    margin: 0 auto;
    padding: 100px 400px 0 400px;
    justify-content: center;
    flex: 1;


}


.body-image img{
    /*background: url(../../assets/bg-map-png.png)  no-repeat ;
    
    background-size: 60%;
    background-position: right;
    background-color: #0085CA;
    background-position-y: 130%;*/

    width: 56%;
    /* background-position: right; */
    background-color: #0085CA;
    background-position-y: 123%;
    z-index: -1;
    position: absolute;
    opacity: 4.5;
    right:0;
    top: -22%;
    display: flex;
}

    
.container-title {
    color: black;
    flex: 1;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}