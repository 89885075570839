.itinerary-hour-container {
    background-color: lightgray;
    flex-direction: row;
    height: 60px;
    padding: 10px;
    display: grid;
    grid-template-columns: 20% 60% 20%;
    gap:0px;
    align-items: center;
    justify-content: center;
}

.icon-clock {
    height: 40px;
    width: 40px;
    align-self: flex-start;
    margin: 10px;
}

.journey-time {
    font-weight: bold;
    font-size: 26px;
    color: black;
    height: 40px;
    margin-top: 10px;
}

.itineraries-back {
    font-weight: bold;
    font-size: 20px;
    color: #fff;
    height: 40px;
    width: 40px;
    margin-top: -20px;
    background: #FFAE24;
    border-radius: 50px;
    text-decoration: none;
    /*background-image: url(../../assets/icons/arrow-voltar.jpg) ;*/
}
.arrow-img{
    width: 40px;
    height: 40px;
    border-radius: 50px;
}
.itineraries-back:before{
    /*content:'\1f860';/*/
   /*background-image: url(../../assets/icons/arrow-voltar.jpg) ;*/
    background-size: 50%;
    margin: 10px;
    margin-top: 5px;
    position: absolute;
    content:'';
}
.no-padding{
    padding:0;
}
.routers {
    display: grid;
    grid-template-columns: 5% 95%;
    gap:0px;
    border-radius: 2px; 
}
.routers .points{
    margin-top: 20px;
}
.routers .result .results{
    /*border-bottom: 1px solid #0096D1;
    padding: 20px 0;*/
    padding: 14px 0px 16px;
}
.routers .result .results .line-button{
    display: grid;
    grid-template-columns: 65% 15%;
    grid-gap: 0px;
    gap: 0px;
    height: 23px;
    border-bottom: 1px solid #0096D1;
    margin-top: -27px;
}

.firstPoint  .dot{
    background-color:#FFAE24;
}
.lastPoint  .locationImg{
    width: 40px;
    margin-top: 0px;
    margin-left: -3px;
    
}
.dot {
    background-color:#fff;
    border: #4A4D4F 2px solid;
    border-radius: 8px;
    border-width: 2px;
    height: 10px;
    top: 5px;
    width: 10px;
    z-index: 1px;
    
}

.dot-text {
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    /*height: 44px;*/
    font-family: var(--Montserrat);

   
}

.dot-text-walk {
    display: grid;
    grid-template-columns: 50% 50%;
    gap:0px;

}
.dot-text-walk  .dot-text:last-child{
    display: flex;
    flex-direction: row-reverse;
}
.line {   
    background-color: #447084;
    height: 30px;
    margin-left: 5px;
    top: 5px;
    width: 4px;
      
}

.line-text-container {
    display: grid;
    grid-template-columns: 60% 15%;
    gap:0px;
    height: 30px;   
}

.line-text {    
    font-size: 12px;
    font-weight: bold;
    height: 30px;   
    cursor:pointer;

    color: #27C0F8;
    font-family: var(--Montserrat);
}

.line-text-selected {
    color: #0e4d97;
    font-size: 12px;
    font-weight: bolder;
    height: 30px;   
    cursor:pointer;
}

.indexNaoSelecionado {
    display: none;
}

.indexSelecionado {
    display: block;
}

.arrow {
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    position: absolute;
    margin: -3px auto;
  }

.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.title-map{
    display: grid;
    grid-template-columns: 5% 45% 50%;
    margin-top: 20px;

    color: #fff;
    font-size: 14px;
    font-family: var(--Montserrat);
    text-transform: uppercase;
    font-weight: 600;
}
.title-map .titleTempoEstimado{
    justify-content: initial;
    display: flex;
    flex-direction: row-reverse;

}


@media (max-width: 768px){
    .itineraries-back{
        z-index: 1;
    }
    .routers .result .results {
        padding: 10px 0px 16px;
    }
    .routers {
        grid-template-columns: 6% 94%;
    }
    .routers .points {
        margin-top: 13px;
    }
    .routers .result .results .line-button {
        grid-template-columns: 90% 10%;
    }
}

@media (min-width: 569px) and (max-width: 768px){
    .itineraries-journey-time, .itineraries-journey-time, .itineraries-journey-time-title, .itineraries-itinerary-description .linkItinerary p {
        font-size: 12px !important;
    }
}

@media (min-width:769px) and  (max-width:1279px){
    .container-main{
        padding:0 5px;
        margin-top: 50px;
    }
    .modal-places-content ul p {
        grid-template-columns: 10% 90%;
    }
    .body-image img {
        top: -6%;
    }

    
 }
 @media (min-width:1280px) and  (max-width:1440px){
 .routers .result .results .line-button {
    grid-template-columns: 90% 10%;
    }
}