.modal-places {
    background-color: transparent; 
    width: 100%; 
    height: 100%;
    /*padding-top: 10px;*/ 
    border: none;
    border-radius: 8px; 
    margin: auto; 
    padding:0 15px;

}

.modal-places-content {
    width: 100%; 
    /*max-width: 600px; */
    height: 70%; 
    margin: auto; 
    /*padding: 10px;*/
    border-radius: 8px; 
    cursor:pointer;
    margin-top: -20px;

}

.modal-places-content ul{
    padding:0;
}

.modal-places-content ul p {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #0096D1;

    padding: 20px 0;
    margin: 0 !important;
    color:#fff;
    font-family: var(--Montserrat);

    font-size:14px;
    
    display: grid;
    grid-template-columns: 3% 95%;
}
.modal-places-title {
    font-size: 15px;
    font-weight: 400;
    margin-left: 6px;
    color:#fff;
    font-family: var(--Montserrat);
    text-transform: uppercase;
}

.location-img{
    padding: 0 15px 0px 5px;
    margin-top: -11px;
    width:35px;
}

@media (min-width:769px) and  (max-width:1279px){
    .container-main{
        padding:0 5px;
        margin-top: 50px;
    }
    .modal-places-content ul p {
        grid-template-columns: 10% 90%;
    }
 }