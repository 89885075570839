@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body{
    
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: absolute;
    background-color: #0085CA;
    height: 100vh;
    width: 100%;
}
.body-image img{
    /*background: url(../../assets/bg-map-png.png)  no-repeat ;
    
    background-size: 60%;
    background-position: right;
    background-color: #0085CA;
    background-position-y: 130%;*/

    width: 56%;
    /* background-position: right; */
    background-color: #0085CA;
    background-position-y: 123%;
    z-index: -1;
    position: absolute;
    opacity: 4.5;
    /*margin: auto 44%;*/
    top: -22%;
    display: flex;
    right:0;
    z-index: -1;
}

    

:root{
    --Roboto:'Roboto', sans-serif;
    /*--Oswald:'Oswald', sans-serif;
    --Ubuntu:'Ubuntu', sans-serif;
    --Bebas:'Bebas Neue', sans-serif;*/
    --Montserrat:'Montserrat', sans-serif;
  
    --primary-color:#004A99;
    --secundary-color:#009DE7;
    --font-color: #686868;
    
  }

.container-main {
    /*width: 100%;*/
    /*max-width: 700px;*/
    /*margin: 20px auto 0;*/
    margin:0 auto;
    padding: 150px 200px 0 200px;
    justify-content: center;
    flex: 1;
    /*background: #00aff5;*/

}
.no-padding{
    padding:0;
}

.container-header {
    /*background: #00aff5;*/
    justify-content: center;
    padding: 0 15px;
}

.container-header .grid-title{
    /*display:grid;
    grid-template-columns: 98% 2%;    */
    justify-content: space-between;
    display: flex;
    align-items: center;

}
.header-searchBoxContainer {
    align-self: stretch;
    background-color: white;
    border-Radius: 20px;
    flex-direction: row;
    height: 100px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 0 15px;
    /*display: grid;
    grid-template-columns: 5% 90% 5%;
    gap:0px;*/

}

.header-imageNavigate {
    align-self: center;
    width: 13px;
    margin-top: 0px;
}

.header-imageSwitch {
    height: 25px;
    width: 25px;
}

.header-searchBox {
    align-content: stretch;
    padding-right: 10px;
    align-self: center;
    width: 100%;

    display: grid;
    grid-template-columns: 3% 90% 7%;
    gap: 2px;
}

.rotas-img{
    align-self: center;
    margin-top: 0px;
    padding: 24px;
    cursor:pointer;
}
.container-searchInputTextDestiny {
    /*height: 20px;*/
    height: 45px;
    display: grid;
    grid-template-columns: 10% 90%;
    gap:6px;
    padding: 2px 0;
    /*margin-top: 10px;*/
    position: relative;
  
}

.text-searchInputTextDestiny,
.header-searchInputTextDestiny {
    font-size: 16px;
    font-weight: bold;
    color: #C3D2D8;
    /*height: 20px;*/
    padding: 10px 0;
    text-align: left;
    font-family: var(--Montserrat);
}

.header-searchInputTextDestiny {
    height: 40px;
    border:none;
    padding:0px 32px  0px 0px;
}

.container-searchInputTextOrigin {
    /*height: 20px;*/
    height: 45px;
    display: grid;
    grid-template-columns: 10% 90%;
    gap:6px;
    padding: 2px 0;
    /*margin-bottom: 10px;*/
    border: 1px solid #eee;
    border-top: none;
    border-left: none;
    border-right: none;
    position:relative;

    
}
.tabela .container-searchInputTextOrigin span{
    position: absolute;
    left: 98%;
    top: calc(53% - 0.5em);
    margin: 0 auto;
    display: flex;
    cursor: pointer;
    transform: rotate(45deg);
    color: #ddd;
    font-weight: 100;
    font-size: 15px;
}

.tabela .container-searchInputTextDestiny span{
    position: absolute;
    left: 98%;
    top: calc(47% - 0.5em);
    margin: 0 auto;
    display: flex;
    cursor: pointer;
    transform: rotate(45deg);
    color: #ddd;
    font-weight: 100;
    font-size: 15px;
}


.tabela .container-searchInputTextOrigin span:hover,
.tabela .container-searchInputTextOrigin span:focus,
.tabela .container-searchInputTextOrigin span:visited,
.tabela .container-searchInputTextDestiny span:hover,
.tabela .container-searchInputTextDestiny span:focus,
.tabela .container-searchInputTextDestiny span:visited{
    color:#0085CA;
    transition: all .1s ease-in-out;
}


.text-searchInputTextOrigin,
.header-searchInputTextOrigin {
    font-size: 16px;
    font-weight: bold; 
    /*height: 20px;*/
    color:#258ECA;
    text-align: left;
    padding: 15px 0;
    font-family: var(--Montserrat);
}

.header-searchInputTextOrigin {
    height: 45px;
    border:none;
    /*padding: 0;*/
    padding:0px 32px  0px 0px;

    
}
input:focus,
input:hover{
    border:none;
}
.header-searchInputTextOrigin:focus,
.header-searchInputTextDestiny:focus{
    outline:none;
}

.header-separator {
    background-color: silver;
    height: 1px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 5px;
}

.container-footer {
    align-items: center;
    background-color: '#dfe6ef';
    flex: 2;
    justify-content: center;
   padding:0 35px;
}

.footer-lines {
    align-self: stretch;
    flex: 8;
    /*padding: 0 15px;*/
}

.footer-lines-places {
    align-self: stretch;
    flex: 8;
}

.itineraries-empty {
    margin: 20px;
    font-size: 14px;
    font-weight: bold;
    color:#fff;
    font-family: var(--Montserrat);
    display: flex;
}
.itineraries-empty div:nth-child(1){
    color:#33C9FF;
    padding-right:3px;
}

.itineraries-loading{
    margin: 20px 0;
    font-size: 14px;
    font-weight: bold;
    text-transform: unset;
    color:#fff;
    font-family: var(--Montserrat);
    font-style: italic;


}




  
  .logo-img{
    width: 20%;
  }
  
  .topo-header{
    padding: 0 40px 0 40px;
  
  }
  .header-title {
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    flex: 1;
    left: -50;
    font-family: var(--Ubuntu);
    margin-top:-10px;
  
  }
  
  .leaflet-marker-pane img{
    width: 35px !important;
    margin-top: -39px !important;
  }


  /*responsividade*/
  

  @media (max-width:768px) { 
    .container-main{
        width: 100% !important;
        padding: 15px 0px !important;
        margin-top: -28px;
    }
    .logo-img {
        width: 150px;
        align-items: center !important;
        margin:30px auto;
        display:block;
    }
    .container-header{
        padding: 0 5px;
    }
    .leaflet-marker-pane img {
        width: 30px !important;
        margin-top: -32px !important;
        height: 32px !important;
    }
    .header-searchInputTextOrigin,.header-searchInputTextDestiny{
        z-index: 1;
        padding-right: 21px;
        font-size: 13px;
        padding: 0px 24px 0px 0px;
    }
    .text-searchInputTextOrigin,.text-searchInputTextDestiny{
        text-align: center;
        font-size: 14px;
    }
    .container-searchInputTextOrigin,.container-searchInputTextDestiny{
        grid-template-columns: 30% 69%;
        gap: 5%;
        

    }
    .tabela .container-searchInputTextOrigin span,.tabela .container-searchInputTextDestiny span{
        z-index: 1;
        font-size: 12px;
        left:94%;
    }
    .header-searchBoxContainer{
        margin-left: 5px;
        margin-right: 5px;
        padding: 0 10px;
    }
    .header-searchBox{
        grid-template-columns: 7% 80% 13%;
        padding: 0;
        gap: 0%;
    }
    .rotas-img{
        padding: 0 7px;
        width: 30px;
        z-index: 1;
    }
    .footer-lines{
        padding:0
    }
    .itineraries-empty{
        display: block;
        margin: 0 ;
    }

    .modal-places-content ul p{
        grid-template-columns: 10% 90%;
    }

    .body-image {
        background-size: 100%;     
        background-position-y: -2%;
        position: absolute;
    }

    /*tela de resultados*/
    .container-footer{
        padding: 0 5px;
        margin-left: 5px;
        margin-right: 5px;
    }
    .itineraries-card{
        /*gap:5px;*/
        padding: 10px 10px !important;
        grid-template-columns: 20% 20% 60%;
    }
    .itineraries-journey-time,.itineraries-journey-time,.itineraries-journey-time-title,
    .itineraries-itinerary-description .linkItinerary p{
        font-size: 10px !important;
    }

    .modal-places{
        padding:0 !important;
    }
    .modal-places-content ul p{
        font-size: 13px;
        grid-template-columns: 10% 90% !important;
    }

    .body-image img {
        width: 100% !important;
        background-color: #0085CA;
        z-index: -1;
        position: relative  !important;
        opacity: 4.5;
    }
    .itineraries-itinerary-description a {
        color: #fff;
        text-decoration: none;
        font-weight: 600;
        background-color: #FF9E3E;
        border-radius: 8px;
        padding: 6px 20px 15px 13px;
        border-bottom-left-radius: 0;
        width: 0;
        height: 10px;
        text-align: left;
        font-size: 10px;
        margin-top: 6px;
    }
    
    .itinerariosMap{
        padding:5px;
      
      }





  }
  @media (min-width:375px) and (max-width:479px){ 
    .header-searchInputTextOrigin,.header-searchInputTextDestiny{
        z-index: 1;
        padding-right: 21px;
        font-size: 13px;
        padding: 0px 30px 0px 0px;
    }
  }
  @media (min-width:480px)  and (max-width:559px){ 
    .header-searchInputTextOrigin,.header-searchInputTextDestiny{
        z-index: 1;
        padding-right: 21px;
        font-size: 13px;
        padding: 0px 40px 0px 0px;
    }
  }
  @media (min-width:560px) and (max-width:578px){ 
    .header-searchInputTextOrigin,.header-searchInputTextDestiny{
        z-index: 1;
        padding-right: 21px;
        font-size: 13px;
        padding: 0px 45px 0px 0px;
    }
  }

  @media (min-width:769px) and  (max-width:1279px){
    .container-main{
        padding:0 5px;
        margin-top: 50px;
    }
    .modal-places-content ul p {
        grid-template-columns: 10% 90%;
    }
    .body-image img {
        top: -6%;
    }
    .container-searchInputTextOrigin,.container-searchInputTextDestiny{
        gap:10;
    }
 }


  @media (min-width:1280px) and  (max-width:1440px) { 
    .container-main{
        padding:0;
    }
    .itineraries-empty{
        margin: 0 ;
        display: -webkit-inline-box;
    }
    .rotas-img{
        padding: 3px;
        width: 30px;
        z-index: 1;
    }
    .container-searchInputTextOrigin
    {
        grid-template-columns: 15% 85%;
        gap:6%;
    }
    .tabela .container-searchInputTextOrigin span,.tabela .container-searchInputTextDestiny span{
        font-size: 13px;
        left: 94%;
    }
    .container-searchInputTextDestiny{
        grid-template-columns: 15% 85%;
        gap:6%;
    }
    .modal-places-content ul p{        
        grid-template-columns: 8% 92% !important;
    }
    .modal-places{
        padding:0 !important;
    }
    .body-image img{
        right:0;
        
    }
    .itineraries-itinerary-description{
        grid-template-columns: 80% 20%;
    }
    .header-searchInputTextOrigin,.header-searchInputTextDestiny{
     
        padding: 0px 60px 0px 0px;
    }
    
  }

  
  /*@media (max-width: 575.98px) { ... }
  
   Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) { ... }
  
  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) { ... }
  
  // Large devices (desktops, less than 1200px)
  @media (max-width: 1199.98px) { ... }*/