.itineraries-footer {
    align-items: center;
    background-color: '#dfe6ef';
    flex: 1.5;
    justify-content: center;
    
}

.itineraries-header-result {
    flex: 0.5;
    flex-direction: row;
    justify-content: space-between;
}

.itineraries-text-results {
    align-self: center;
    flex: 1;
    font-size: 13px;
    font-weight: 600;
    /*margin-left: 10px;*/
    color:#fff;
    font-style: italic;
    font-family: var(--Montserrat);
    display: flex;

}
.itineraries-text-results .resultados{
    margin-left: 3px;
    margin-right: 3px;
    display: inline-flex;
}
.itineraries-text-results .resultados .buttonClose{
    background-color: #0075B1;
    border: none;
    width: 28px;
    height: 28px;
    position: relative;
    margin: -6px 15px;
    cursor:pointer;
}
.itineraries-text-results .resultados .buttonClose:focus{
    outline: none;
}

.itineraries-text-results .resultados .buttonClose:after {
    content: '';
    height: 15px;
    border-left: 2px solid #fff;
    position: absolute;
    transform: rotate(45deg);
    margin: -7px -1px;
    
}

.itineraries-text-results .resultados .buttonClose:before {
    content: '';
    height: 15px;
    border-left: 2px solid #fff;
    position: absolute;
    transform: rotate(-45deg);
    margin: -7px -1px;
    
}




.itineraries-text-results .result .buttonClose
.itineraries-list-container {
    align-self: stretch;
    flex: 3;
}

.itineraries-card {
    background-color: transparent;
    flex: 1;
    margin-bottom: 5px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-top: 10px;   
    border-radius: 20px; 
    background-color:#4B5151;
    padding: 20px 25px;

    display: grid;
    grid-template-columns: 100%;

}


.itineraries-journey-time {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}
.itineraries-journey-time:nth-child(1){
    font-weight: 600;
    font-size: 25px;
}
.itineraries-journey-time:nth-child(2){
    font-weight: 600;
    font-size: 20px;
}


.itineraries-journey-time-title{
    text-transform: uppercase;
    color: #0086CA;
    font-weight: 600;
    font-size: 14px;
}

.itineraries-itinerary-description{
    flex: 1;
    flex-direction: row;

    display: grid;
    grid-template-columns: 90% 10%;
}

.itineraries-itinerary-description p{
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    margin: 0;
}
.itineraries-itinerary-description  a {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    background-color: #FF9E3E;
    border-radius: 15px;
    padding: 12px 20px 15px 17px;
    border-bottom-left-radius: 0;
    width: 11px;
    height: 20px;
    text-align: left;
}

@media (max-width: 768px){
    .itineraries-itinerary-description{
        grid-template-columns: 83% 17%;
    }
    .itineraries-itinerary-description a {
        color: #fff;
        text-decoration: none;
        font-weight: 600;
        background-color: #FF9E3E;
        border-radius: 8px;
        padding: 6px 20px 15px 13px;
        border-bottom-left-radius: 0;
        width: 0;
        height: 10px;
        text-align: left;
        font-size: 10px;
        margin-top: 6px;
    }
}

@media (min-width:1280px) and  (max-width:1440px) { 
    .itineraries-journey-time:nth-child(1){
        font-size: 20px;
    }
    .itineraries-journey-time:nth-child(1){
        font-size: 20px;
    }
    .itineraries-card{
        gap:5px;
    }
    .itineraries-itinerary-description{
        grid-template-columns: 80% 20%;
    }
    .itineraries-itinerary-description p {
        font-weight: 600;
        font-size: 14px;
        color: #fff;
        margin: 5px 0;
    }
}